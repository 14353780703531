// Definitions

(function() {

  if (typeof window.BAR === "undefined") {
    window.BAR = {};
  }

  BAR.createBasicCarousels = function() {
    var $basicCarouselContainers = $('.carousel-basic');

    for (var i = 0; i < $basicCarouselContainers.length; i++) {
      var $container = $( $basicCarouselContainers[i] );

      var $list = $container.find('>ul');

      $list.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        prevArrow: $container.find('.carousel__arrow-left'),
        nextArrow: $container.find('.carousel__arrow-right'),
        adaptiveHeight: true,
        // autoplay: true,
        autoplaySpeed: 6000
      });
    }
  }

})();


// Invocations

$(function () {

  var $basicCarousel = $('.carousel-basic > ul');
  $basicCarousel.show();

  BAR.createBasicCarousels();

});
