// Definitions

(function() {

  if (typeof window.BAR === "undefined") {
    window.BAR = {};
  }

  var Accordion = BAR.Accordion = function(settings) {
    this.$el = settings.$el;
    this.$trigger = settings.$trigger;
    this.triggerAction = settings.triggerAction;

    this.$collapsible = this.$el.find('>.collapsible');

    this._bindTriggerAction();
  };

  Accordion.prototype = {
    _bindTriggerAction: function() {

      var that = this;

      this.$trigger.on(this.triggerAction, function(e) {

        e.preventDefault();

        if ( that.$el.hasClass('collapsed') ) {
          that.open();
        } else {
          that.close();
        }
      });
    },
    open: function(instant) {

      this.$collapsible.slideDown(150);
      this.$trigger.attr('aria-expanded', true);
      this.$el.removeClass('collapsed');
    },
    close: function(instant) {

      this.$collapsible.slideUp(150);
      this.$trigger.attr('aria-expanded', false);
      this.$el.addClass('collapsed');
    }
  };

})();


// Invocations

$(function () {

  // nav-main-2
  var $navAccordions = $('.nav-main-2 .nav-item.collapsible-container');

  for (var i = 0; i < $navAccordions.length; i++) {
    var $navAccordion = $( $navAccordions[i] );
    var $a = $navAccordion.find('>a');

    var navAccordion = new BAR.Accordion({
      $el: $navAccordion,
      $trigger: $a,
      triggerAction: 'click'
    });
  }

  // FAQs
  var $faqs = $('.block-faq__faq.collapsible-container');

  for (var i = 0; i < $faqs.length; i++) {
    var $faq = $( $faqs[i] );
    var $button = $faq.find('.faq-question > button');

    var faqAccordion = new BAR.Accordion({
      $el: $faq,
      $trigger: $button,
      triggerAction: 'click'
    });
  }

});
