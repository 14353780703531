// Definitions

(function() {

  if (typeof window.BAR === "undefined") {
    window.BAR = {};
  }

})();


// Invocations

$(function () {

  //

});
