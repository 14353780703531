// Definitions

(function() {

  if (typeof window.BAR === "undefined") {
    window.BAR = {};
  }

  var callbackIndex = 0;

  var callbacks = BAR.windowResizeCallbacks = {
    universal: [],
    desktop: [],
    mobile: []
  }

  BAR.addResizeCallback = function(callback, breakpoint) {
    var callbackWrapper = {
      index: callbackIndex,
      callback: callback
    }

    callbacks[breakpoint].push(callbackWrapper);

    callbackIndex ++;

    return callbackWrapper.index;
  }

  BAR.removeResizeCallback = function(callbackIndex) {
    var deleted = false;

    _.each(callbacks, function(breakpointCallbacks) {

      if (deleted) {
        return;
      }

      var breakpointIndex = _.findIndex(breakpointCallbacks, function(wrapper) {
        return wrapper.index === callbackIndex;
      });

      if (breakpointIndex > -1) {
        
        breakpointCallbacks.splice(breakpointIndex, 1);

        deleted = true;
      }
    });

    return deleted;
  }

  BAR.onWindowResize = function() {
    var $window = $(window);
    var width = $window.width();

    callbacks.universal.forEach( function(wrapper) {
      wrapper.callback.call();
    });

    if (768 <= width) {

      callbacks.desktop.forEach( function(wrapper) {
        wrapper.callback.call();
      });
    } else if (width < 768) {

      callbacks.mobile.forEach( function(wrapper) {
        wrapper.callback.call();
      });
    }
  }

})();


// Invocations

$(function () {

  var $window = $(window);

  var debouncedResize = _.debounce( BAR.onWindowResize, 100 );

  $window.on('resize', debouncedResize);

});
