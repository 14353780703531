// Definitions

(function() {

  if (typeof window.BAR === "undefined") {
    window.BAR = {};
  }

  // Flattens existing $menu, adding back and forward nav buttons
  var NavMenu = BAR.NavMenu = function(settings) {
    this.$menu = settings.$menu;
    this.$trigger = settings.$trigger;

    this._boundOnKeyup = this._onKeyup.bind(this);

    this._initMenu();
  };

  NavMenu.prototype = {
    _bindTriggerClick: function() {
      var that = this;

      this.$trigger.click( function(e) {
        if ( that.$menu.parent().hasClass('visible') ) {

          that.hideMenu();
          BAR.hideBodyScreen(true);
        } else {

          that.showMenu();
          BAR.showBodyScreen(true);
        }
      });
    },
    showMenu: function() {
      BAR.hideSearchOverlay();

      this.setActiveMenuFromPath();

      this.$trigger
        .addClass('is-active')
        .attr('aria-expanded', 'true');

      var that = this;

      this.$menu
        .fadeIn(0, function() {

          that.$menu.parent().addClass('visible');
        });

      this._bindFocusHandler();
    },
    hideMenu: function() {
      var that = this;

      this.$menu
        .fadeOut(0, function() {

          that.$menu.parent().removeClass('visible');

          that.$trigger
            .removeClass('is-active')
            .attr('aria-expanded', 'false');
        });

      this._unbindFocusHandler();
    },
    _bindNavButtonClick: function() {
      var that = this;

      this.$menu.find('button.nav-button').click( function(e) {
        var $button = $(e.currentTarget);

        var transition = {
          origin: $button.hasClass('nav-button-prev') ? 'left' : 'right',
          duration: 150
        };

        that.setActiveMenu({
          menuID: $button.attr('data-menu-id'),
          transition: transition
        });
      });
    },
    _initMenu: function() {
      var lists = [
        this.$menu.find('>ul').attr('data-menu-id', 0)
      ];
      var processedLists = [];

      this.currentListIndex = 0;
      var currentList;
      var processedList;

      while (lists.length > 0) {

        $currentList = lists.shift();

        processedList = this._createList($currentList);

        processedLists.push(processedList.$list);

        lists = lists.concat(processedList.sublists);
      }

      this.$menu
        .html(processedLists)
        .addClass('nav-processed')

      this.$menu.find('>ul').hide();

      this.setActiveMenuFromPath();
      this._bindTriggerClick();
      this._bindNavButtonClick();
    },
    _createList: function($parentList) {
      if ( !$parentList.attr('data-menu-id') ) {
        this.currentListIndex ++;

        $parentList.attr('data-menu-id', this.currentListIndex);
      }

      var $items = $parentList.find('>li');
      var processedItems = [];
      var sublists = [];

      for (var i = 0; i < $items.length; i++) {
        var $item = $( $items[i] );

        var classList = Array.prototype.join.call($item[0].classList, ' ');

        // Clone $item without child tree
        var $li = $('<li>')
          .html( $item.find('>*:not(ul)').clone() )
          .addClass(classList);

        var $sublist = $item.find('>ul');

        if ($sublist.length > 0) {

          this.currentListIndex ++;

          $sublist.attr('data-menu-id', this.currentListIndex);

          // Add li with parent link to child list
          $sublist.prepend(
            this._createListItem($li.clone(), $parentList.attr('data-menu-id'), null)
          );

          sublists.push($sublist);

          // Add child link to parent li without cloning child tree
          processedItems.push(
            this._createListItem($li.clone(), null, $sublist.attr('data-menu-id'))
          )

        } else {
          processedItems.push($li.clone());
        }
      }

      // Create new list with items that only have links
      var $list = $('<ul>')
        .addClass('menu')
        .attr('data-menu-id', $parentList.attr('data-menu-id'))
        .attr('aria-expanded', 'false');

      if ($parentList.hasClass('nav-sub')) {
        $list.addClass('menu-sub');
      } else {
        $list.addClass('menu-root');
      }

      $list.html(processedItems);

      return {
        $list: $list,
        sublists: sublists
      }
    },
    _createListItem: function($li, parentID, childID) {
      var $button = $('<button>');

      if (parentID) {
        $button
          .addClass('icon icon-chevron-left nav-button nav-button-prev')
          .attr('data-menu-id', parentID);

        $li
          .prepend($button)
          .addClass('nav-item-parent');

      } else if (childID) {

        $button
          .addClass('icon icon-chevron-right nav-button nav-button-next')
          .attr('data-menu-id', childID);

        $li.append($button);
      }

      return $li;
    },
    setActiveMenuFromPath: function() {
      var currentPath = window.location.pathname;
      var width = $(window).width();

      if (currentPath === '/' || width >= 960) {

        this.setActiveMenu({
          menuID: 0,
          transition: false
        });

      } else {

        var $links = this.$menu.find('a[href="' + currentPath + '"]');
        var $targetLink;
        var $li;

        if ($links.length > 1) {

          var targetLink = _.find($links, function(link) {
            var $link = $(link);

            var isOriginal = $link
              .siblings('button')
              .hasClass('nav-button-prev');

            return isOriginal;
          });

          $targetLink = $(targetLink);

        } else {

          $targetLink = $links;
        }

        this.setActiveMenu({
          $li: $targetLink.parent(),
          transition: false
        });
      }
    },
    setActiveMenu: function(options) {
      var transition = options.transition;
      var $activeSubmenu = this.$menu.find('>ul.menu-active');
      var $newSubmenu;

      if (options.hasOwnProperty('menuID')) {

        $newSubmenu = this.$menu.find('>ul[data-menu-id="' + options.menuID + '"]');
      } else if (options.$li) {

        $newSubmenu = options.$li.parent();
      }

      if ( $activeSubmenu.is($newSubmenu) ) {
        return;
      }

      if (transition && $activeSubmenu.length === 1) {

        $activeSubmenu
          .addClass('transition')
          .css(transition.origin === 'right' ? 'left' : 'right', 'auto')
          .css(transition.origin === 'right' ? 'right' : 'left', '0%');

        $newSubmenu
          .addClass('transition')
          .css(transition.origin === 'right' ? 'right' : 'left', 'auto')
          .css(transition.origin === 'right' ? 'left' : 'right', '100%')
          .show();

        setTimeout( function() {
          animation = {};

          submenuAnimationActive = transition.origin === 'right' ? { right: '100%' } : { left: '100%' };
          submenuAnimationNew = transition.origin === 'right' ? { left: '0%' } : { right: '0%' };

          $activeSubmenu.animate(
            submenuAnimationActive, transition.duration, 'swing'
          );

          $newSubmenu.animate(
            submenuAnimationNew, transition.duration, 'swing', function() {
            $activeSubmenu
              .hide()
              .removeClass('menu-active transition')
              .attr('aria-expanded', 'false');

            $newSubmenu
              .removeClass('transition')
              .addClass('menu-active')
              .attr('aria-expanded', 'true');
          });
        }, 0);

      } else {
        $activeSubmenu
          .hide()
          .css('right', 'auto')
          .css('left', 'auto')
          .removeClass('menu-active')
          .attr('aria-expanded', 'false');

        $newSubmenu
          .css('right', 'auto')
          .css('left', 'auto')
          .show()
          .addClass('menu-active')
          .attr('aria-expanded', 'true');
      }
    },
    _bindFocusHandler: function() {
      $(document).on('keydown', this._boundOnKeyup);
    },
    _unbindFocusHandler: function() {
      $(document).off('keydown', this._boundOnKeyup);
    },
    _onKeyup: function(e) {
      var $currentFocus = $(e.target);
      var $currentFocusParent = $currentFocus.parent();

      if (e.which === 9) { // Tab key

        if (
          $currentFocusParent.hasClass('nav-item')
          && $currentFocusParent.is(':last-child')
        ) {

          e.preventDefault();
          this.$trigger.focus();
        } else if ( $currentFocus.is(this.$trigger) ) {

          e.preventDefault();
          this.$menu.find('.menu.menu-active > li:first-child > *:first-child').focus();
        }
      }
    }
  };

})();


// Invocations

$(function () {

  var menu = new BAR.NavMenu({
    $menu: $('.nav-main'),
    $trigger: $('.trigger-wrapper__nav-main > button')
  });

  BAR.hideMainMenu = menu.hideMenu.bind(menu);

});
