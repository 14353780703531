// Definitions

(function() {

  if (typeof window.BAR === "undefined") {
    window.BAR = {};
  }

  var $searchOverlay = $('#search-form-container');
  var $trigger = $('.trigger-wrapper__search button');
  var $input = $searchOverlay.find('input[name="keyword"]');
  var $submit = $searchOverlay.find('button[type="submit"]');

  BAR.showSearchOverlay = function() {
    BAR.hideMainMenu();

    $trigger
      .addClass('is-active')
      .attr('aria-expanded', 'true');

    $searchOverlay.fadeIn(0, function() {
      $searchOverlay.addClass('visible');
    });

    $input.focus();

    bindFocusHandler();
  };

  BAR.hideSearchOverlay = function(instant) {
    if (instant === true) {

      $searchOverlay
        .hide()
        .removeClass('visible');

      $trigger
        .removeClass('is-active')
        .attr('aria-expanded', 'false')
    } else {

      $searchOverlay.fadeOut(0, function() {
        $searchOverlay.removeClass('visible');

        $trigger
          .removeClass('is-active')
          .attr('aria-expanded', 'false')
      });
    }

    unbindFocusHandler();
  };

  function bindFocusHandler() {
    $(document).on('keydown', onKeyup);
  }

  function unbindFocusHandler() {
    $(document).off('keydown', onKeyup);
  }

  function onKeyup(e) {
    var $currentFocus = $(e.target);

    if (e.which === 9) { // Tab key

      if ( $currentFocus.is($trigger) ) {

        e.preventDefault();
        $input.focus();
      } else if ( $currentFocus.is($submit) ) {

        e.preventDefault();
        $trigger.focus();
      }
    }
  }

})();


// Invocations

$(function () {

  var $trigger = $('.trigger-wrapper__search > button');

  $trigger.click( function(e) {

    if ( $trigger.hasClass('is-active') ) {

      BAR.hideBodyScreen(true);
      BAR.hideSearchOverlay();
    } else {

      BAR.showBodyScreen(true);
      BAR.showSearchOverlay();
    }
  });

});
