// Definitions

(function() {

  if (typeof window.BAR === "undefined") {
    window.BAR = {};
  }

})();


// Invocations

$(function () {

  if (Modernizr.flexbox) {
    $.get('/vendor/flexibility/flexibility.js')
      .done( function() {

        flexibility( document.getElementById('main-content') );
      });
  }

});
