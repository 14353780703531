// Definitions

(function() {

  if (typeof window.BAR === "undefined") {
    window.BAR = {};
  }

  var $bodyScreen = $('<div id="body-screen">');
  var $body = $('body');
  var $document = $(document);

  var resizeCallback;

  function bindBodyScreenHandlers() {
    $body.on('click', onClickBody);
    $document.on('keyup', onKeyup);

    resizeCallback = BAR.addResizeCallback(hideOverlays, 'universal');
  }

  function unbindBodyScreenHandlers() {
    $body.off('click', onClickBody);
    $document.off('keyup', onKeyup);

    var blah = BAR.removeResizeCallback(resizeCallback);
  }

  function onKeyup(e) {
    if (e.which === 27) { // Esc key
      hideOverlays();
    }
  }

  function onClickBody(e) {
    if (e.target.id === 'body-screen') {
      hideOverlays();
    }
  }

  function hideOverlays() {
    BAR.hideBodyScreen();
    BAR.hideMainMenu();
    BAR.hideSearchOverlay();
  }

  BAR.showBodyScreen = function(instant) {
    $body.css('overflow', 'hidden');

    if (instant === true) {

  		$('#main-content').append($bodyScreen);

      setTimeout( function() {
        $bodyScreen.show();
      }, 0);
  	} else {
      $bodyScreen.hide();

      $('#main-content').append($bodyScreen);

      setTimeout( function() {
        $bodyScreen.fadeIn(0);
      }, 0);
  	}

    bindBodyScreenHandlers();
  };

  BAR.hideBodyScreen = function(instant) {
    $body.css('overflow', 'auto');

    if (instant === true) {

  		$bodyScreen.remove();
  	} else {

  		$bodyScreen.fadeOut(0, function() {
        $bodyScreen.remove();
      });
  	}

    unbindBodyScreenHandlers();
  };

})();


// Invocations

$(function () {

  //

});
